import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';

export const DocMediaConsent = ({consent}) => {
  let consentSignature = '';
  let consentDate = '';
  let rejectSignature = '';
  let rejectDate = '';

  if(consent) {
    if(consent.media === 'consent') {
      consentSignature = 'Consent given online';
      consentDate = moment(consent.date).format('MM-DD-YYYY')
    } else {
      rejectSignature = 'Consent rejected online';
      rejectDate = moment(consent.date).format('MM-DD-YYYY')
    }
  }

  return (
    <View>
      <Text style={styles.h2}>Permission to Photograph and/or Video your Child</Text>
      <Text style={styles.h3}>Parents/Guardians: Please sign either A or B below</Text>
      <View style={{flex: 1, flexDirection: 'row'}}>
        <Text style={{margin: 12, fontSize: 24}}>
          A
        </Text>
        <Text style={styles.text}>
          I hereby give my consent to photograph and/or video my child for the purpose of showing pictures and/or video to the congregation of Bridgewater Community Church.
        </Text>
      </View>
      <View style={styles.formRow}>
        <Text style={styles.fieldName}>Signature</Text>
        <Text style={styles.fieldBlank_3}>{consentSignature}</Text>
        <Text style={styles.fieldName}>Date</Text>
        <Text style={styles.fieldBlank_1}>{consentDate}</Text>
      </View>
      <View style={{flex: 1, flexDirection: 'row'}}>
        <Text style={{margin: 12, fontSize: 24}}>
          B
        </Text>
        <View style={{flexShrink: 1, flex: 1}}>
          <Text style={styles.text}>
            I do NOT give permission to have my child photographed and/or videoed at BCC
          </Text>
        </View>
      </View>
      <View style={styles.formRow}>
        <Text style={styles.fieldName}>Signature</Text>
        <Text style={styles.fieldBlank_3}>{rejectSignature}</Text>
        <Text style={styles.fieldName}>Date</Text>
        <Text style={styles.fieldBlank_1}>{rejectDate}</Text>
      </View>
      <Text style={styles.sectionBreak}> </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  h2: {
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  h3: {
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  text: {
    margin: 12,
    fontSize: 12,
    flexShrink: 1,
    flexGrow: 1,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  formRow: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 7,
    fontSize: 16,
  },
  sectionBreak: {
  	borderBottom: 5,
    padding: '1 1',
    margin: 9,
    flexGrow: 1,
    fontSize: 1,
  },
  fieldBlank: {
	  borderBottom: 1,
    padding: '1 10',
    flexGrow: 10,
  },
  fieldBlank_1: {
	  borderBottom: 1,
    padding: '1 5',
    flexGrow: 1
  },
  fieldBlank_3: {
	  borderBottom: 1,
    padding: '1 10',
    flexGrow: 3
  },
  fieldName: {
    margin: '1 5 1 10',
    flexGrow: 0
  },
});

export default DocMediaConsent;