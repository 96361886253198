import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

export const DocEmergency = ({contact}) => {
  const phone = contact ? `(${contact.phone.slice(0,3)}) ${contact.phone.slice(3,6)}-${contact.phone.slice(6)}` : ''

  return (
    <>
      <View style={styles.spacer}></View>
      <View style={styles.formRow}>
        <Text style={styles.fieldName}>Emergency Contact if parent or guardian cannot be reached</Text>
      </View>
      <View style={styles.formRow}>
        <Text style={styles.fieldName}>Name</Text>
        <Text style={styles.fieldBlank_3}>{contact?.name}</Text>
        <Text style={styles.fieldName}>Relationship</Text>
        <Text style={styles.fieldBlank_2}>{contact?.relationship}</Text>
      </View>
      <View style={styles.formRow}>
        <Text style={styles.fieldName}>Phone Number</Text>
        <Text style={styles.fieldBlank}>{phone}</Text>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  formRow: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 7,
    fontSize: 16,
  },
  fieldBlank: {
	  borderBottom: 1,
    padding: '1 10',
    flexGrow: 10,
    fontSize: 12,
  },
  fieldBlank_2: {
	  borderBottom: 1,
    padding: '1 10',
    flexGrow: 2,
    fontSize: 12,
  },
  fieldBlank_3: {
	  borderBottom: 1,
    padding: '1 10',
    flexGrow: 3,
    fontSize: 12,
  },
  fieldName: {
    margin: '1 5 1 10',
    flexGrow: 0
  },
  spacer: {
    marginBottom: 10,
  },
});

export default DocEmergency;