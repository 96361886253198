import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

export const DocMedical = ({medical}) => {
  let line1 = '';
  let line2 = '';
  let line3 = '';
  
  if(medical) {
    const medWords = medical.split(' ');
    for(const word of medWords) {
      if(line1.length < 45) {
        line1 = line1 + word + ' ';
      } else if(line2.length < 85) {
        line2 = line2 + word + ' ';
      } else if(line3.length < 85) {
        line3 = line3 + word + ' ';
      }
      
    }
  }
  return (
    <>
      <View style={styles.spacer}></View>
      <View style={styles.formRow}>
        <Text style={styles.fieldName}>Medical history, allergies, medications</Text>
        <Text style={styles.fieldBlank}>{line1}</Text>
      </View>
      <View style={styles.formRow}>
        <Text style={styles.fieldName}> </Text>
        <Text style={styles.fieldBlank}>{line2}</Text>
      </View>
      <View style={styles.formRow}>
        <Text style={styles.fieldName}> </Text>
        <Text style={styles.fieldBlank}>{line3}</Text>
      </View>
    </>
  )
};

const styles = StyleSheet.create({
  formRow: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 7,
    fontSize: 14,
  },
  fieldBlank: {
	  borderBottom: 1,
    padding: '1 10',
    flexGrow: 10,
    fontSize: 12,
  },
  fieldName: {
    margin: '1 5 1 10',
    flexGrow: 0
  },
  spacer: {
    marginBottom: 10,
  },
});

export default DocMedical;