import React from 'react';
import moment from 'moment';

import { Text, View, StyleSheet } from '@react-pdf/renderer';

export const DocChild = ({child}) => {
  const birthdate = moment(child?.birthdate, 'YYYY-MM-DD');
  const age = moment().diff(birthdate, 'years');

  return (
    <>
      <View style={styles.formRow}>
        <Text style={styles.fieldName}>Child's Name</Text>
        <Text style={styles.fieldBlank}>{child?.name}</Text>
      </View>
      <View style={styles.formRow}>
        <Text style={styles.fieldName}>Age</Text>
        <Text style={styles.fieldBlank_1}>{child?.birthdate && age}</Text>
        <Text style={styles.fieldName}>Birth date</Text>
        <Text style={styles.fieldBlank_1}>{child?.birthdate && birthdate.month()}</Text>
        <Text style={styles.fieldName_1}>/</Text>
        <Text style={styles.fieldBlank_1}>{child?.birthdate && birthdate.daysInMonth()}</Text>
        <Text style={styles.fieldName_1}>/</Text>
        <Text style={styles.fieldBlank_1}>{child?.birthdate && birthdate.year()}</Text>
        <Text style={styles.fieldName}>Grade</Text>
        <Text style={styles.fieldBlank_1}>{child?.grade}</Text>
        <Text style={styles.fieldName}>School</Text>
        <Text style={styles.fieldBlank}>{child?.school}</Text>
      </View>
    </>
  )
};

const styles = StyleSheet.create({
  formRow: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 7,
    fontSize: 14,
  },
  fieldBlank: {
	  borderBottom: 1,
    padding: '1 10',
    flexGrow: 10,
  },
  fieldBlank_1: {
  	borderBottom: 1,
    padding: '1 5',
    flexGrow: 1
  },
  fieldName: {
    margin: '1 5 1 10',
    flexGrow: 0
  },
  fieldName_1: {
    margin: '1 1',
    flexGrow: 0
  },
});

export default DocChild;