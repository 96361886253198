import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

export const DocPickup = ({allowed, restricted}) => {
  let allowedLine1 = '';
  let allowedLine2 = '';
  let restrictedLine1 = '';
  let restrictedLine2 = '';
  if(allowed) {
    const formattedAllowed = allowed.replace(/\\r\\n/g,', ').replace(/\r\n/g,', ').replace(/\s+/g,' ');
    [allowedLine1, allowedLine2] = splitLines(formattedAllowed);
  }
  if(restricted) {
    const formattedRestricted = restricted.replace(/\\r\\n/g,', ').replace(/\r\n/g,', ').replace(/\s+/g,' ');
    [restrictedLine1, restrictedLine2] = splitLines(formattedRestricted);
  }

  return (
    <>
      <View style={styles.spacer}></View>
      <View style={styles.formRow}>
        <Text style={styles.fieldName}>Adults authorized to pick up child</Text>
        <Text style={styles.fieldBlank}>{allowedLine1}</Text>
      </View>
      <View style={styles.formRow}>
        <Text style={styles.fieldName}> </Text>
        <Text style={styles.fieldBlank}>{allowedLine2}</Text>
      </View>
      <View style={styles.formRow}>
        <Text style={styles.fieldName}>Adults NOT authorized to pick up child</Text>
        <Text style={styles.fieldBlank}>{restrictedLine1}</Text>
      </View>
      <View style={styles.formRow}>
        <Text style={styles.fieldName}> </Text>
        <Text style={styles.fieldBlank}>{restrictedLine2}</Text>
      </View>
    </>
  );
};

const splitLines = text => {
  let line1 = '';
  let line2 = '';
  
  if(text) {
    const words = text.split(' ');
    for(const word of words) {
      if(line1.length < 45) {
        line1 = line1 + word + ' ';
      } else if(line2.length < 85) {
        line2 = line2 + word + ' ';
      }
    }
  }
  return [line1, line2];
}

const styles = StyleSheet.create({
  formRow: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 7,
    fontSize: 16,
  },
  fieldBlank: {
	  borderBottom: 1,
    padding: '1 10',
    flexGrow: 10,
    fontSize: 12,
  },
  fieldName: {
    margin: '1 5 1 10',
    flexGrow: 0
  },
  spacer: {
    marginBottom: 10,
  },
});

export default DocPickup;