import React from 'react';
import { Font, Text, View, StyleSheet } from '@react-pdf/renderer';

export const DocHeader = ({year}) => (
  <View style={styles.header}>
    <Text style={styles.title}>GEMS Girls' Club</Text>
    <Text>{year}</Text>
    <Text style={styles.location}>Bridgewater Community Church</Text>
    <Text>7984 County Road p-50</Text>
    <Text>Montpelier, OH 43543</Text>
    <Text>(419) 485-8229</Text>
  </View>
);

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  location: {
    fontSize: 14,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: 'center',
  },
});

export default DocHeader;