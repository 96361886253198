import React, { useState, useEffect } from 'react';
import SingleRegistration from './single-registration';

const RegistrationList = ({code}) => {
  const [regList, setRegList] = useState([]);
  const [token, setToken] = useState(typeof window !== 'undefined' && localStorage.getItem('token'));

  useEffect(() => {
    if(regList && regList.length === 0) {
      if (typeof window !== 'undefined') {
        fetch(`/.netlify/functions/google-sheet-request?auth=${code}${token ? `&token=${token}` : ''}`)
          .then(response => response.json())
          .then(body => {
              localStorage.setItem('token', JSON.stringify(body.token))
            setToken(body.token);
            setRegList(body.rows);
          })
      }
    }
  }, [code, regList, token]);

  const readRow = (row) => {
    const childCount = row[13];
    const children = [];

    for(let ndx = 0; ndx < childCount; ndx++) {
      children.push({
        name: row[ndx*5+14],
        birthdate: row[ndx*5+15],
        school: row[ndx*5+16],
        grade: row[ndx*5+17],
        medical: row[ndx*5+18],
      })
    }

    return {
      parent:{
        name: row[0],
        email: row[1],
        address: row[2],
        city: row[3],
        state: row[4],
        zip: row[5],
        church: row[6],
        phone: row[7].match(/\d/g).join(''),
      },
      emergencyContact: {
        name: row[8],
        relationship: row[9],
        phone: row[10].match(/\d/g).join(''),
      },
      pickup: {
        allowed: row[11],
        restricted: row[12],
      },
      children,
      consent: {
        medical: row[44],
        nonConsentProcedure: row[45],
        media: row[46],
        transport: row[47],
        date: row[52],
      },
      feeCapable: row[48],
      meta: {
        ip: row[49],
        userAgent: row[50],
        referrer: row[51],
        createdAt: row[52],
      },
    }
  }

  return(
    <>
      {(regList.length > 0) ? (
          regList.map((reg) => {
            const parsedReg = readRow(reg);
            return parsedReg.children.map((child) => (
              <div key={`${parsedReg.parent.name}-${child.name}`}>
                <h3>{parsedReg.parent.name} - {child.name}</h3>
                <SingleRegistration key={`${parsedReg.parent.name}-${child.name}`} registration={parsedReg} child={child} />
              </div>
            ))
          })
        ) : <h3>Loading...</h3>
      }
      <h3>Blank</h3>
      <SingleRegistration key={'blank'}/>
    </>
  )
}

export default RegistrationList;