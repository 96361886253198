import React from 'react';
import { PDFDownloadLink} from '@react-pdf/renderer';

import SingleRegistrationDoc from './single-registration-doc';

const SingleRegistration = ({registration, child}) => {

  const pdfDoc = <SingleRegistrationDoc registration={registration} child={child} />;

  return (
    <div className="row gtr-uniform">
      <div className="col-3">
        <button>
          <PDFDownloadLink document={pdfDoc} fileName={`${child?.name || 'blank'}-registration.pdf`}>
            {({ loading }) => (loading ? 'Loading document...' : 'Download')}
          </PDFDownloadLink>
        </button>
      </div>
    </div>
  )
}

export default SingleRegistration;