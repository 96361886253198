import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

export const DocSignature = () => (
  <>
    <View style={styles.spacer}></View>
    <View style={styles.formRow}>
      <Text style={styles.fieldName}>Signature of Parent or Guardian</Text>
      <Text style={styles.fieldBlank}></Text>
    </View>
    <View style={styles.spacer}></View>
    <View style={styles.formRow}>
      <Text style={styles.fieldName}>Print Name</Text>
      <Text style={styles.fieldBlank_3}></Text>
      <Text style={styles.fieldName}>Date</Text>
      <Text style={styles.fieldBlank_1}></Text>
    </View>
  </>
);

const styles = StyleSheet.create({
  formRow: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 7,
    fontSize: 16,
  },
  fieldBlank: {
  	borderBottom: 1,
    padding: '1 10',
    flexGrow: 10,
  },
  fieldBlank_1: {
	borderBottom: 1,
    padding: '1 5',
    flexGrow: 1
  },
  fieldBlank_3: {
	borderBottom: 1,
    padding: '1 10',
    flexGrow: 3
  },
  fieldName: {
    margin: '1 5 1 10',
    flexGrow: 0
  },
  spacer: {
    marginBottom: 10,
  },
});

export default DocSignature;