import React from 'react';
import { Text, View } from '@react-pdf/renderer';

export const DocFooter = () => (
  <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', fontSize: 14, marginTop: 5}}>
    <View style={{flexGrow: 3}}>
      <Text style={{margin: 'auto', marginBottom: 4}}>Registration Fee: $20</Text>
      <Text style={{margin: 'auto'}}>Make check payable to:</Text>
      <Text style={{margin: 'auto', marginBottom: 4}}>Bridgewater Community Church</Text>
      <Text style={{margin: 'auto'}}>Scholarships are available</Text>
      <Text style={{margin: 'auto'}}>Please contact the church at (419) 485-8229</Text>
    </View>
    <View style={{flexGrow: 2, border: 1}}>
      <View style={{flex: 1, flexDirection: 'row'}}>
        <Text style={{margin: 2, border: 3}}>    </Text>
        <Text style={{margin: 'auto'}}>Awareness Level</Text>
        <Text style={{margin: 'auto'}}>Grades 1-3</Text>
      </View>
      <View style={{flex: 1, flexDirection: 'row'}}>
        <Text style={{margin: 2, border: 3}}>    </Text>
        <Text style={{margin: 'auto'}}>Discovery Level</Text>
        <Text style={{margin: 'auto'}}>Grades 4-5</Text>
      </View>
      <View style={{flex: 1, flexDirection: 'row'}}>
        <Text style={{margin: 2, border: 3}}>    </Text>
        <Text style={{margin: 'auto'}}>Advanced Level</Text>
        <Text style={{margin: 'auto'}}>Grades 6-8</Text>
      </View>
        <Text style={{margin: 2}}>Mentor:</Text>
    </View>
  </View>
);
export default DocFooter;