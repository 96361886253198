import React, { useState } from "react"
import queryString from 'query-string';

import Layout from "../components/layout"
import SEO from "../components/seo"
import RegistrationList from "../components/registration-list"

const AdminPage = (query) => {
  const [oauthUrl, setOauthUrl] = useState();
  let code = '';

  if(query && query.location && query.location.search){
    const queriedString = queryString.parse(query.location.search);
    code = queriedString.code;
  }

  if(!code) {
    if(typeof window !== 'undefined'){
      localStorage.removeItem('token');
      fetch("/.netlify/functions/google-oauth")
        .then(response => response.json())
        .then(body => setOauthUrl(body.authUrl))
    }
  }

  return (
    <Layout noContactForm>
      <SEO title="Admin" />
      <h2>GEMS Admin</h2>
      {oauthUrl && !code &&
        <h3><a href={oauthUrl}>login</a></h3>
      }
      {code && <RegistrationList code={code} />}
    </Layout>
  )
}

export default AdminPage;
