import React from 'react';
import { Document, Page, View, StyleSheet } from '@react-pdf/renderer';

import {
  DocHeader,
  DocChild,
  DocParent,
  DocMedical,
  DocPickup,
  DocEmergency,
  DocSignature,
  DocFooter,
  DocMedicalConsent,
  DocMediaConsent,
  DocTransportConsent,
} from './registration-doc';

const SingleRegistrationDoc = ({registration, child}) => (
  <Document>
    <Page style={styles.body}>
      <DocHeader year='2020-2021' />
      <View>
        <DocChild child={child} />
        <DocParent parent={registration?.parent} />
        <DocMedical medical={child?.medical} />
        <DocPickup
          allowed={registration?.pickup?.allowed}
          restricted={registration?.pickup?.restricted}
        />
        <DocEmergency contact={registration?.emergencyContact} />
        <DocSignature />
        <DocFooter />
      </View>
    </Page>
    <Page style={styles.body}>
      <DocMedicalConsent consent={registration?.consent} />
      <DocMediaConsent consent={registration?.consent} />
      <DocTransportConsent consent={registration?.consent} />
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
});

export default SingleRegistrationDoc;