import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

export const DocParent = ({parent}) => {
  const phone = parent ? `(${parent.phone.slice(0,3)}) ${parent.phone.slice(3,6)}-${parent.phone.slice(6)}` : ''
  return (
    <>
      <View style={styles.formRow}>
        <Text style={styles.fieldName}>Parent/Guardian</Text>
        <Text style={styles.fieldBlank_3}>{parent?.name}</Text>
        <Text style={styles.fieldName}>Phone Number</Text>
        <Text style={styles.fieldBlank_2}>{phone}</Text>
      </View>
      <View style={styles.formRow}>
        <Text style={styles.fieldName}>Address</Text>
        <Text style={styles.fieldBlank}>{parent?.address}</Text>
      </View>
      <View style={styles.formRow}>
        <Text style={styles.fieldName}>City</Text>
        <Text style={styles.fieldBlank}>{parent?.city}</Text>
        <Text style={styles.fieldName}>State</Text>
        <Text style={styles.fieldBlank_1}>{parent?.state}</Text>
        <Text style={styles.fieldName}>Zip</Text>
        <Text style={styles.fieldBlank_3}>{parent?.zip}</Text>
      </View>
      <View style={styles.formRow}>
      <Text style={styles.fieldName}>Email Address</Text>
        <Text style={styles.fieldBlank_3}>{parent?.email}</Text>
        <Text style={styles.fieldName}>Home Church</Text>
        <Text style={styles.fieldBlank_2}>{parent?.church}</Text>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  formRow: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 7,
    fontSize: 14,
  },
  fieldBlank: {
	  borderBottom: 1,
    padding: '1 10',
    flexGrow: 10,
    fontSize: 12,
  },
  fieldBlank_1: {
	  borderBottom: 1,
    padding: '1 5',
    flexGrow: 1,
    fontSize: 12,
  },
  fieldBlank_2: {
	 borderBottom: 1,
    padding: '1 10',
    flexGrow: 2,
    fontSize: 12,
  },
  fieldBlank_3: {
	 borderBottom: 1,
    padding: '1 10',
    flexGrow: 3,
    fontSize: 12,
  },
  fieldName: {
    margin: '1 5 1 10',
    flexGrow: 0
  },
});

export default DocParent;